import React from 'react';
import { connect } from 'react-redux';

import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import BatchSizeSelector from './BatchSizeSelector.js';
import ListType from './ListType.js';
import ReviewMode from './ReviewMode.js';
import SpellableAccordion from './SpellableAccordion.js';
import SpellableAccordionSummary from './SpellableAccordionSummary.js';
import SpellableButton from './SpellableButton.js';
import SpellableSwitch from './SpellableSwitch.js';
import SubListHandle from './SubListHandle.js';

import { 
  prefetchWords,
} from './actions.js';

class ListDetails extends React.Component {
  constructor(props) {
    super(props);
    this.onButtonPress = this.onButtonPress.bind(this);
    this.onOptionSelect = this.onOptionSelect.bind(this);
  }

  getSubListSwitchState(sub_list_handle) {
    if (sub_list_handle in this.props.subListRestartState) {
      return this.props.subListRestartState[sub_list_handle];
    } else {
      return false;
    }
  }

  getSubListFromList(selected_list, sub_list_handle) {
    return selected_list.subLists.filter(
      x => x['handle'] === sub_list_handle,
    )[0];
  }

  getStem(concept_type) {
    return (concept_type === "ROOTS") ? "roots" : "words";
  }

  getSuffixedTitle(sub_list_name, concept_type, sub_list_length) {
    const stem = this.getStem(concept_type);
    return (sub_list_name + " (" + sub_list_length + " " + stem + ") ");
  }
 
  onButtonPress(
    selected_list,
    sub_list_handle,
    review_mode,
    start_index,
    button_length,
  ) {
    const selected_value = selected_list.id;

    this.props.prefetchWords(
      this.props.authToken, 
      selected_value, 
      sub_list_handle,
      review_mode,
      start_index,
      button_length,
    );
  }

  onOptionSelect(batch_size) {
    this.props.onMultiButtonSelect(batch_size);
  }

  renderButton(
    prefix,
    selected_list,
    sub_list_handle,
    review_mode,
    start_index,
    max_length,
  ) {
    const concept_type = selected_list.conceptType;
    const button_length = (max_length < (this.props.batchSize * 1.5)) ?
      max_length : this.props.batchSize;

    var button_title = "";
    if (concept_type === "SPELLING") {
      button_title = button_length + " Words";
    } else if (concept_type === "VOCAB") {
      button_title = button_length + " Vocab";
    } else if (concept_type === "ROOTS") {
      button_title = button_length + " Roots";
    }

    const onclick_lambda = (
      () => this.onButtonPress(
        selected_list,
        sub_list_handle,
        review_mode,
        start_index,
        button_length,
      )
    );

    return ( 
      <Stack 
        direction="row" 
        alignItems="center" 
        justifyContent="center"
        spacing={2}
        sx={{ paddingBottom: 2 }}>
        <Typography variant="body2">
          {prefix}
        </Typography>
        <SpellableButton 
          variant="outlined"
          size="medium"
          onClick={onclick_lambda}
          disabled={button_length === 0}>
          {button_title}
        </SpellableButton>
      </Stack>
    );
  }

  renderRestartSwitch(sub_list_handle) {
    const label = "Restart from beginning of list";
    const toggle_lambda = (
      (checked) => { 
        this.props.onSwitchToggle(sub_list_handle, checked)
      }
    );

    return (
      <SpellableSwitch
        checked={this.getSubListSwitchState(sub_list_handle)}
        label={label}
        onChange={toggle_lambda} />
    );
  }

  renderDailyReview(selected_list) {

    const sub_list = this.getSubListFromList(
      selected_list, 
      SubListHandle.DAILY_REVIEW_SUB_LIST_HANDLE,
    );

    const suffixed_title = this.getSuffixedTitle(
      sub_list.name,
      selected_list.conceptType,
      sub_list.length,
    );

    const remaining = sub_list.length - sub_list.testCompleted;
    const prefix = "Test Mode: ";
    const review_button = this.renderButton(
      prefix, 
      selected_list,
      SubListHandle.DAILY_REVIEW_SUB_LIST_HANDLE,
      ReviewMode.TEST_MODE,
      /* start_index = */ -1, 
      remaining,
    );

    const panel_lambda = (
      (evt, expanded) => { 
        this.props.onPanelToggle(evt, expanded, sub_list.handle)
      }
    );

    return (
      <SpellableAccordion
        key={sub_list.handle}
        onChange={panel_lambda}
        expanded={this.props.expandedPanelHandle === sub_list.handle}>
        <SpellableAccordionSummary>
          <Typography variant="body1">
            {suffixed_title}&nbsp;
          </Typography>
        </SpellableAccordionSummary>
        <AccordionDetails>
          <Stack 
            alignItems="center" 
            spacing={1}
            sx={{ pt: 1 }} >
            {review_button}
          </Stack>
        </AccordionDetails>
      </SpellableAccordion>
    );
  }

  renderRegularLists(selected_list) {
    var sub_list;
    var sub_list_results = [];

    for(sub_list of selected_list.subLists) {
      if (sub_list.handle === SubListHandle.DAILY_REVIEW_SUB_LIST_HANDLE ||
          sub_list.handle === SubListHandle.FREQ_MISS_SUB_LIST_HANDLE ||
          sub_list.handle === SubListHandle.INFREQ_REVIEW_SUB_LIST_HANDLE ||
          sub_list.handle === SubListHandle.UNREVIEWED_SUB_LIST_HANDLE) {
        continue;
      }

      sub_list_results.push(
        this.renderRegularList(selected_list, sub_list)
      );
    }

    return (
      <React.Fragment>
        {sub_list_results}
      </React.Fragment>
    );
  }
    
  renderRegularList(selected_list, sub_list) {

    const suffixed_title = this.getSuffixedTitle(
      sub_list.name,
      selected_list.conceptType,
      sub_list.length,
    );

    const switch_state = this.getSubListSwitchState(sub_list.handle);
    const test_remaining = switch_state ?
      sub_list.length : (sub_list.length - sub_list.testCompleted);
    const test_prefix = 
      "Test Mode (" + sub_list.testCompleted + " / " + sub_list.length + ") ";

    const test_button = this.renderButton(
      test_prefix,
      selected_list,
      sub_list.handle,
      ReviewMode.TEST_MODE,
      /* start_index = */ switch_state ? 0 : -1,
      test_remaining,
    );

    const browse_remaining = switch_state ?
      sub_list.length : (sub_list.length - sub_list.browseCompleted);
    const browse_prefix = 
      "Browse Mode (" + sub_list.browseCompleted + " / " + sub_list.length + ") ";
    const browse_button = this.renderButton(
      browse_prefix,
      selected_list,
      sub_list.handle,
      ReviewMode.BROWSE_MODE,
      /* start_index = */ switch_state ? 0 : -1,
      browse_remaining,
    );
   
    const panel_lambda = (
      (evt, expanded) => { 
        this.props.onPanelToggle(evt, expanded, sub_list.handle)
      }
    );

    const restart_toggle = this.renderRestartSwitch(sub_list.handle);

    return (
      <SpellableAccordion
        key={sub_list.handle}
        onChange={panel_lambda}
        expanded={this.props.expandedPanelHandle === sub_list.handle}>
        <SpellableAccordionSummary
          expandIcon={<ExpandMoreIcon />} >
          <Typography variant="body1">
            {suffixed_title}&nbsp;
          </Typography>
        </SpellableAccordionSummary>
        <AccordionDetails>
          <Stack
            alignItems="center" 
            spacing={1}
            sx={{ pt: 1 }} >
            {test_button}
            {browse_button}
            {restart_toggle}
          </Stack>
        </AccordionDetails>
      </SpellableAccordion>
    );
  }
    
  renderUnreviewedList(selected_list) {

    const sub_list = this.getSubListFromList(
      selected_list, 
      SubListHandle.UNREVIEWED_SUB_LIST_HANDLE,
    );

    const suffixed_title = this.getSuffixedTitle(
      sub_list.name,
      selected_list.conceptType,
      sub_list.length,
    );

    const switch_state = this.getSubListSwitchState(sub_list.handle);
    const test_remaining = switch_state ?
      sub_list.length : (sub_list.length - sub_list.testCompleted);
    const test_prefix = 
      "Test Mode (" + sub_list.testCompleted + " / " + sub_list.length + ") ";
    const test_button = this.renderButton(
      test_prefix,
      selected_list,
      SubListHandle.UNREVIEWED_SUB_LIST_HANDLE,
      ReviewMode.TEST_MODE,
      /* start_index = */ switch_state ? 0 : -1,
      test_remaining,
    );

    const browse_remaining = switch_state ?
      sub_list.length : (sub_list.length - sub_list.browseCompleted);
    const browse_prefix = 
      "Browse Mode (" + sub_list.browseCompleted + " / " + sub_list.length + ") ";
    const browse_button = this.renderButton(
      browse_prefix,
      selected_list,
      SubListHandle.UNREVIEWED_SUB_LIST_HANDLE,
      ReviewMode.BROWSE_MODE,
      /* start_index = */ switch_state ? 0 : -1,
      browse_remaining,
    );

    const panel_lambda = (
      (evt, expanded) => { 
        this.props.onPanelToggle(evt, expanded, sub_list.handle)
      }
    );

    const restart_toggle = this.renderRestartSwitch(
      SubListHandle.UNREVIEWED_SUB_LIST_HANDLE,
    );

    return (
      <SpellableAccordion
        key={sub_list.handle}
        onChange={panel_lambda}
        expanded={this.props.expandedPanelHandle === sub_list.handle}>
        <SpellableAccordionSummary
          expandIcon={<ExpandMoreIcon />} >
          <Typography variant="body1">
            {suffixed_title}&nbsp;
          </Typography>
        </SpellableAccordionSummary>
        <AccordionDetails>
          <Stack
            alignItems="center" 
            spacing={1}
            sx={{ pt: 1 }} >
            {test_button}
            {browse_button}
            {restart_toggle}
          </Stack>
        </AccordionDetails>
      </SpellableAccordion>
    );
  }
    
  renderFrequentlyMissedList(selected_list) {

    const sub_list = this.getSubListFromList(
      selected_list,
      SubListHandle.FREQ_MISS_SUB_LIST_HANDLE,
    );

    const suffixed_title = this.getSuffixedTitle(
      sub_list.name,
      selected_list.conceptType,
      sub_list.length,
    );

    const switch_state = this.getSubListSwitchState(sub_list.handle);
    const test_remaining = switch_state ?
      sub_list.length : (sub_list.length - sub_list.testCompleted);

    const test_prefix = 
      "Test Mode (" + sub_list.testCompleted + " / " + sub_list.length + ") ";
    const test_button = this.renderButton(
      test_prefix, 
      selected_list,
      SubListHandle.FREQ_MISS_SUB_LIST_HANDLE,
      ReviewMode.TEST_MODE,
      /* start_index = */ switch_state ? 0 : -1,
      test_remaining,
    );

    const browse_remaining = switch_state ?
      sub_list.length : (sub_list.length - sub_list.browseCompleted);
    const browse_prefix = 
      "Browse Mode (" + sub_list.browseCompleted + " / " + sub_list.length + ") ";
    const browse_button = this.renderButton(
      browse_prefix,
      selected_list,
      SubListHandle.FREQ_MISS_SUB_LIST_HANDLE,
      ReviewMode.BROWSE_MODE,
      /* start_index = */ switch_state ? 0 : -1,
      browse_remaining,
    );
  
    const panel_lambda = (
      (evt, expanded) => { 
        this.props.onPanelToggle(evt, expanded, sub_list.handle)
      }
    );

    const restart_toggle = this.renderRestartSwitch(
      SubListHandle.FREQ_MISS_SUB_LIST_HANDLE,
    );

    return (
      <SpellableAccordion
        key={sub_list.handle}
        onChange={panel_lambda}
        expanded={this.props.expandedPanelHandle === sub_list.handle}>
        <SpellableAccordionSummary
          expandIcon={<ExpandMoreIcon />} >
          <Typography variant="body1">
            {suffixed_title}&nbsp;
          </Typography>
        </SpellableAccordionSummary>
        <AccordionDetails>
          <Stack
            alignItems="center" 
            spacing={1}
            sx={{ pt: 1 }} >
            {test_button}
            {browse_button}
            {restart_toggle}
          </Stack>
        </AccordionDetails>
      </SpellableAccordion>
    );
  }
    
  render() {
    const list_id = this.props.listId;

    if (list_id === "") {
      return (
        <Stack
          alignItems="center" 
          spacing={1}
          sx={{ pt: 1 }} />
      );
    }

    const selected_list = this.props.listsMetadataById[list_id];
    const stem = this.getStem();

    var details; 
    if (selected_list.listType === ListType.NON_REVIEW) {
      details = (
        <Container fixed>
          {this.renderDailyReview(selected_list)}
          {this.renderUnreviewedList(selected_list)}
          {this.renderFrequentlyMissedList(selected_list)}
          {this.renderRegularLists(selected_list)}
        </Container>
      );
    } else if (selected_list.listType === ListType.DAILY_REVIEW) {
      details = (
        <Container fixed>
          {this.renderDailyReview(selected_list)}
        </Container>
      );
     
    } else if (selected_list.listType === ListType.FREQUENTLY_MISSED) {
      details = (
        <Container fixed>
          {this.renderFrequentlyMissedList(selected_list)}
        </Container>
      );
    }

    return (
      <Stack alignItems="center" spacing={2}>
        <Card elevation={0}>
          <CardContent>
            <Stack alignItems="center" spacing={1}>
              <Typography 
                variant="body1"
                sx={{ fontWeight: "bold" }}>
                {selected_list.name}
              </Typography>
              <Typography variant="body2">
                {"List Length: " + selected_list.listLength + " " + stem}
              </Typography>
              <Typography variant="body2">
                {"Reviewed: " + selected_list.reviewed + " " + stem}
              </Typography>
              <Box sx={{pt: 3}}>
                <BatchSizeSelector 
                  batchSize={this.props.batchSize}
                  onOptionSelect={this.onOptionSelect} />
              </Box>
            </Stack>
          </CardContent>
        </Card>
        {details}
      </Stack>
    );
  }
}

function mapStateToProps(state) {
  return {
    authToken: state.loginState.authToken,
    listsMetadataById: state.prefetchState.listsMetadataById,
  };
}

const mapDispatchToProps = {
  prefetchWords,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListDetails);

