const SubListHandle = {
  // Hack for now - copied from the server side.
  DAILY_REVIEW_SUB_LIST_HANDLE: "daily-review",
  FREQ_MISS_SUB_LIST_HANDLE: "freq-miss",
  INFREQ_REVIEW_SUB_LIST_HANDLE: "infreq-review",
  UNREVIEWED_SUB_LIST_HANDLE: "unreviewed",
  ALL_SUB_LIST_HANDLE: "all",
};


export default SubListHandle;
