import Constants from './Constants.js';

function getErrorText(response) {
  return response.status + ":" + response.statusText;
}

export async function fetchExists(email) {
  var existence_query = {
    query: "{ exists(email:" + JSON.stringify(email) + ")}"
  }

  // console.log(JSON.stringify(login_query));

  var response = await fetch(Constants.graphQLUrl, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(existence_query),
  });

  if (!response.ok) {
    return [null, getErrorText(response)];
  }

  var response_json = await response.json();

  return [response_json.data.exists, null];
}

export async function genPreVerify(
  parent_email,
  student_email,
  verify_parent,
) {
  var pre_verify_query = {
    query: "mutation {preVerify(" +
              "parentEmail:" + JSON.stringify(parent_email) + "," +
              "studentEmail:" + JSON.stringify(student_email) + "," +
              "verifyParent:" + JSON.stringify(verify_parent) + "," +
              "sendEmail: true," +
            ") {ok}}",
  }


  // console.log(JSON.stringify(login_query));

  var response = await fetch(Constants.graphQLUrl, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(pre_verify_query),
  });

  if (!response.ok) {
    // console.log(getErrorText(response));
    return getErrorText(response);
  }

  var response_json = await response.json();
  // console.log(response_json);

  if (response_json.data.preVerify.ok !== true) {
    return "Pre-verify call failed.";
  }

  return null;
}

export async function fetchVerify(
  parent_email,
  student_email,
  verify_parent,
  password,
) {
  var verify_query = {
    query: "{ verify(" +
              "parentEmail:" + JSON.stringify(parent_email) + "," +
              "studentEmail:" + JSON.stringify(student_email) + "," +
              "verifyParent:" + JSON.stringify(verify_parent) + "," +
              "password:" + JSON.stringify(password) + ")}",
  }

  // console.log(JSON.stringify(login_query));

  var response = await fetch(Constants.graphQLUrl, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(verify_query),
  });

  if (!response.ok) {
    return [null, getErrorText(response)];
  }

  var response_json = await response.json();

  return [response_json.data.verify, null];
}

export async function genCreateAccount(
  parent_email,
  student_email,
  parent_password,
  student_password,
  full_name,
  address_by,
  timezone,
) {
  var create_account_query = {
    query: "mutation {createAccount(" +
              "parentEmail:" + JSON.stringify(parent_email) + "," +
              "studentEmail:" + JSON.stringify(student_email) + "," +
              "parentPassword:" + JSON.stringify(parent_password) + "," +
              "studentPassword:" + JSON.stringify(student_password) + "," +
              "fullName:" + JSON.stringify(full_name) + "," +
              "addressBy:" + JSON.stringify(address_by) + "," +
              "timezone:" + JSON.stringify(timezone) + "," +
            ") {ok}}",
  }

  var response = await fetch(Constants.graphQLUrl, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(create_account_query),
  });

  if (!response.ok) {
    return getErrorText(response)
  }

  var response_json = await response.json();

  if (response_json.data.createAccount.ok !== true) {
    return 'Account creation failed';
  }

  return null;
}

export async function fetchPreLoginInfo(email) {
  var pre_login_query = {
    query: "mutation {preLogin(" +
              "email:" + JSON.stringify(email) + "," +
              "sendEmail: true," +
           ") {ok}}",
  }

  // console.log(JSON.stringify(login_query));

  var response = await fetch(Constants.graphQLUrl, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(pre_login_query),
  });

  if (!response.ok) {
    // console.log(getErrorText(response));
    return getErrorText(response);
  }

  var response_json = await response.json();
  // console.log(response_json);

  if (response_json.data.preLogin.ok !== true) {
    return "Pre-login call failed.";
  }

  return null;
}

export async function fetchLoginInfo(email, password) {
  var login_query = {
    query: "{ login(" +
             "email:" + JSON.stringify(email) + "," +
             "password:" + JSON.stringify(password) + "," +
           "){accessToken, name}}"
  }

  // console.log(JSON.stringify(login_query));

  var response = await fetch(Constants.graphQLUrl, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(login_query),
  });

  if (!response.ok) {
    return [null, null, getErrorText(response)]
  }

  var response_json = await response.json();

  if (response_json.data.login.accessToken === null) {
    return [null, null, getErrorText(response)]
  }

  const access_token = response_json.data.login.accessToken;
  const name = response_json.data.login.name;
  return [name, access_token, null];
}

export async function fetchLists(access_token, concept_type) {
  var lists_query = {
    query: "{lists(conceptType: " + concept_type + ",epoch:0)" +
              "{id, name, handle, conceptType, listType, listLength, reviewed, " +
              " subLists {handle, name, length, testCompleted, browseCompleted}" +
              "}" +
           "}"
  };

  var response = await fetch(Constants.graphQLUrl, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + access_token,
    },
    body: JSON.stringify(lists_query),
  });

  if (!response.ok) {
    return [null, getErrorText(response)]
  }

  var response_json = await response.json();

  const lists = response_json.data.lists;
  return [lists, null];
}

export async function fetchWords(
  access_token, 
  list_id,
  sub_list_handle,
  review_mode,
  start_index, 
  length
) {
  var data = {
    query: "{words(" +
           "  listId: " + JSON.stringify(list_id) + "," +
           "  subListHandle: " + JSON.stringify(sub_list_handle) + "," +
           "  reviewMode: " + review_mode + "," +
           "  startIndex: " + start_index + "," +
           "  length: " + length + ", " +
           "  epoch: 0" +
           ") {" +
           "id, spelling, alt, subIndex, voiceIndex, uniqueKey, chainedId," +
           "displayableWord, root, meanings, examples, notes" +
           "}}",
  };

  var response = await fetch(Constants.graphQLUrl, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + access_token,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    return [null, getErrorText(response)]
  }

  const response_json = await response.json();
  return [response_json.data.words, null]
}

export async function uploadWords(
  access_token,
  list_id,
  sub_list_handle,
  review_mode,
  concept_type,
  total_count,
  correct_count, 
  duration_in_ms,
  mastered,
  unsure,
  incorrect,
  roots,
  problems,
  voice_indices,
) {
  var data = {
    query: "mutation {logReviewedConcepts(" +
              "conceptType:" + concept_type + "," +
              "listId:" + JSON.stringify(list_id) + "," +
              "subListHandle: " + JSON.stringify(sub_list_handle) + ", " +
              "reviewMode:" + review_mode + "," +
              "epoch: 0," +
              "totalCount:" + JSON.stringify(total_count) + "," +
              "correctCount:" + JSON.stringify(correct_count) + "," +
              "durationInSeconds:" + JSON.stringify(Math.floor(duration_in_ms / 1000)) + "," +
              "masteredWords:" + JSON.stringify(mastered) + "," +
              "unsureWords:" + JSON.stringify(unsure) + "," +
              "incorrectWords:" + JSON.stringify(incorrect) + "," +
              "roots:" + JSON.stringify(roots) + "," +
              "problems:" + JSON.stringify(problems) + "," +
              "voiceIndices:" + JSON.stringify(voice_indices) + "," +
            ") {ok}}",
  };

  // console.log(JSON.stringify(data));

  var response = await fetch(Constants.graphQLUrl, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + access_token,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    return [false, getErrorText(response)];
  }

  const response_json = await response.json();
  // console.log("Response = " + JSON.stringify(response_json));

  if (!response_json.data.logReviewedConcepts.ok) {
    return [false, getErrorText(response)];
  }

  return [true, null];
}

export async function fetchSummary(access_token, concept_type) {
  var data = {
    query: "{summary(" +
            "conceptType: " + concept_type + ", epoch: 0" +
            ") {total}}"
  };

  var response = await fetch(Constants.graphQLUrl, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + access_token,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    return [null, getErrorText(response)];
  }

  const response_json = await response.json();

  // console.log("Calling FetchSummary with " + concept_type);
  // console.log(response_json.data.summary);

  return [response_json.data.summary, null];
}
