import ReviewMode from './ReviewMode.js';
import SubListHandle from './SubListHandle.js';

export default class SubListMetadata {
  constructor(
    list_name,
    concept_type,
    sub_list_handle,
    review_mode = ReviewMode.DEFAULT_MODE,
    list_size = 0,
  ) {
    this.listName = list_name;
    this.conceptType = concept_type;
    this.subListHandle = sub_list_handle;
    this.reviewMode = review_mode;
    this.listSize = list_size;

    Object.freeze();
  }

  getStem() {
    return (this.conceptType === "ROOTS") ? "roots" : "words"; 
  }

  getCapitalizedStem() {
    return (this.conceptType === "ROOTS") ? "Roots" : "Words"; 
  }

  getListName() {
    return this.listName.split("(")[0].trim();
  }

  getListSubName() {
    const suffix = this.getCapitalizedStem();
    var result;

    switch (this.subListHandle) {
      case SubListHandle.DAILY_REVIEW_SUB_LIST_HANDLE:
        result = this.listSize + " Review " + suffix;
        break;

      case SubListHandle.UNREVIEWED_SUB_LIST_HANDLE:
        result = this.listSize + " Unreviewed " + suffix;
        break;

      case SubListHandle.FREQ_MISS_SUB_LIST_HANDLE:
        result = this.listSize + " Frequently Missed " + suffix;
        break;

      case SubListHandle.ALL_SUB_LIST_HANDLE:
        result = this.listSize + " " + suffix;
        break;

      default:
        result = null;
        break;
      
    }

    return result;
  }

  getSubListTitle(sub_list_length) {
    var sub_list_name, stem, cap_stem;

    switch (this.subListHandle) {
      case SubListHandle.DAILY_REVIEW_SUB_LIST_HANDLE:
        sub_list_name = "Daily Review";
        stem = this.getStem();
        return (sub_list_name + " (" + sub_list_length + " " + stem + ") ");

      case SubListHandle.ALL_SUB_LIST_HANDLE:
        cap_stem = this.getCapitalizedStem();
        sub_list_name = "All List " + cap_stem;
        stem = this.getStem();
        return (sub_list_name + " (" + sub_list_length + " " + stem + ") ");

      case SubListHandle.UNREVIEWED_SUB_LIST_HANDLE:
        cap_stem = this.getCapitalizedStem();
        sub_list_name = "Unreviewed List " + cap_stem;
        stem = this.getStem();
        return (sub_list_name + " (" + sub_list_length + " " + stem + ") ");

      case SubListHandle.FREQ_MISS_SUB_LIST_HANDLE:
        cap_stem = this.getCapitalizedStem();
        sub_list_name = "Frequently Incorrect List " + cap_stem;
        stem = this.getStem();
        return (sub_list_name + " (" + sub_list_length + " " + stem + ") ");

      default:
        return null;
    }
  }

  getReviewMode() {
    return this.reviewMode;
  }

  getConceptType() {
    return this.conceptType;
  }

  isBrowseMode() {
    return (this.reviewMode === ReviewMode.BROWSE_MODE);
  }

  getSubListHandle() {
    return this.subListHandle;
  }
}
